<template>
  <div class="ant-card">
    <div class="ant-card-body">
      <div class="head-box">
        <div class="invitecode-info">
          <div class="title">{{ title }}</div>
          <div class="flexbox">
            <span class="label">有效时间：</span>
            <span class="date">{{ startTime }} ~ {{ endTime }}</span>
          </div>
        </div>
        <div class="flexbox middle justify" style="margin-bottom: 30px">
          <div class="table-operator">
            <a-button icon="export" type="primary" @click="onExport">导出邀请码</a-button>
          </div>
          <a-form-model
            layout="inline"
            :model="searchForm"
            @submit="handleSearch"
            @submit.native.prevent
          >
            <a-form-model-item>
              <a-select
                style="width: 200px"
                v-model="searchForm.status"
                @change="statusChange"
              >
                <a-select-option v-for="item in statusList" :key="item.key">{{
                  item.value
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item>
              <a-input
                v-model="searchForm.toNickNameOrUserName"
                placeholder="请输领取会员昵称/账号"
              >
                <a-icon
                  slot="prefix"
                  type="search"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-input v-model="searchForm.uniqueId" placeholder="请输入邀请码">
                <a-icon
                  slot="prefix"
                  type="search"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-model-item>
            <a-form-model-item label="领取日期">
              <a-range-picker @change="onChangeDate" />
            </a-form-model-item>
            <a-form-item>
              <a-button icon="search" type="primary" html-type="submit"
                >搜索</a-button
              >
            </a-form-item>
          </a-form-model>
        </div>
      </div>
      <div class="content-box">
        <a-table
          :columns="columns"
          :data-source="tableList"
          :loading="tableLoading"
          :pagination="false"
        >
          <span slot="operation" slot-scope="operation, record">
            <a class="margin_right20" @click="shareClick(record)">分享</a>
            <a
              v-if="isShowDisabled"
              class="btn-invalid mr20"
              @click="handleInvalid(record.uniqueId)"
            >立即失效</a>
          </span>
        </a-table>
        <!-- 分享的弹框 -->
        <a-modal v-model="shareModalVisible" :footer="null" width="40%">
          <div class="share">分享</div>
          <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="H5">
              <div>
                <span style="margin-right: 20px">链接</span>
                <a-input
                  placeholder="H5链接"
                  disabled
                  style="width: 80%"
                  v-model="shareInputVal"
                />
                <a-button
                  id="copy_text"
                  :data-clipboard-text="shareInputVal"
                  type="primary"
                  style="margin-left: 20px"
                  @click="handleCopyFun"
                >
                  复制
                </a-button>
              </div>
            </a-tab-pane>
          </a-tabs>
        </a-modal>
        <!-- 分页功能 -->
        <MyPagination :count="count" @showSizeChangeFn="showSizeChangeFn" />
      </div>
    </div>
  </div>
</template>

<script>
import Clipboard from "clipboard"; // 复制文字
import MyPagination from "@/components/pagination/MyPagination";
import {
  RecordCodeApi,
  ExportCodeApi,
  InvitationCodeSetStatusApi
} from "@/request/api/marketingCenter";
import { baseMixin } from "@/store/app-mixin";
import { Modal, message } from "ant-design-vue";
import { codeFn } from "@/utils/tools";

export default {
  mixins: [baseMixin],
  components: {
    MyPagination,
  },
  data() {
    return {
      isShowDisabled: false, // 是否显示立即失效按钮
      statusList: [{ key: "", value: "全部状态" }],
      title: "",
      startTime: "",
      endTime: "",
      count: 1,
      searchForm: {
        pageNo: 1,
        pageSize: 20,
        orderNo: "",
        status: "",
        toNickNameOrUserName: "",
        uniqueId: "",
        startTime: "",
        endTime: "",
      },
      tableList: [],
      tableLoading: false,
      columns: [
        { title: "邀请码", dataIndex: "uniqueId", key: "uniqueId" },
        {
          title: "领取会员",
          dataIndex: "toUserNickName",
          key: "toUserNickName",
        },
        { title: "领取会员账号", dataIndex: "toUserName", key: "toUserName" },
        { title: "领取状态", dataIndex: "statusText", key: "statusText" },
        {
          title: "领取时间",
          dataIndex: "receiveTime",
          key: "receiveTime",
          sorter: (a, b) => {
            let aTime = new Date(a.receiveTime).getTime();
            let bTime = new Date(b.receiveTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      shareInputVal: null, // 分享弹框的input值
      shareModalVisible: false, // 分享弹框
    };
  },
  created() {
    if (codeFn("/admin/dealer/invitation-record/setStatus")) {
      this.isShowDisabled = true;
    }
    let dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
    dictionaryObj.getStatus.forEach((item) => this.statusList.push(item));
    let data = JSON.parse(this.$route.params.data);
    this.searchForm.orderNo = data.orderNo;
    this.searchForm.agentUserId = data.agentUserId;
    this.title = data.title;
    this.startTime = data.startTime;
    this.endTime = data.endTime;
    this.RecordCodeApi();
  },
  methods: {
    // 搜索 - 邀请码领取状态
    statusChange(val) {
      this.searchForm.status = val;
      this.searchForm.pageNo = 1;
      this.RecordCodeApi();
    },
    // 搜索 - 选择时间
    onChangeDate(date, dateString) {
      if (dateString[0] != "") {
        this.searchForm.startTime = dateString[0] + " 00:00:00";
        this.searchForm.endTime = dateString[1] + " 23:59:59";
      } else {
        this.searchForm.startTime = "";
        this.searchForm.endTime = "";
      }
    },
    // 搜索事件
    handleSearch(e) {
      this.searchForm.pageNo = 1;
      this.RecordCodeApi();
    },
    // 分页切换页数
    showSizeChangeFn(pageNo, pageSize) {
      this.searchForm.pageNo = pageNo;
      this.searchForm.pageSize = pageSize;
      this.RecordCodeApi();
    },
    RecordCodeApi() {
      this.tableLoading = true;
      RecordCodeApi(this.searchForm).then((res) => {
        if (res.code == 200) {
          this.count = res.data.count;
          let newData = res.data.data;
          newData.forEach((item) => {
            item.key = item.uniqueId;
            item.toUserName = item.toUserName == "" ? "——" : item.toUserName;
            item.toUserNickName =
              item.toUserNickName == "" ? "——" : item.toUserNickName;
            item.receiveTime =
              item.receiveTime == null ? "——" : item.receiveTime;
          });
          this.tableList = newData;
        }
        this.tableLoading = false;
      });
    },
    // 导出邀请码
    onExport() {
      window.location.href =
        ExportCodeApi + "?orderNo=" + this.searchForm.orderNo;
    },
    // 立即失效
    handleInvalid(uniqueId) {
      const that = this;
      Modal.confirm({
        title: "设为失效后，邀请码将不可继续使用!",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          // {
          //   uniqueId: uniqueId,
          //   status: 2 // 状态，当前固定传 2
          // }
          InvitationCodeSetStatusApi(`uniqueId=${uniqueId}&status=2`).then((res) => {
            if (res.code == 200 && res.data) {
              that.$message.success("失效成功");
              that.RecordCodeApi();
            } else {
              that.$message.error("失效失败");
            }
          });
        },
      });
    },
    // 分享的按钮
    shareClick(record) {
      this.shareModalVisible = true;
      this.shareInputVal = record.link;
    },
    // 分享弹框复制按钮
    handleCopyFun() {
      let clipboard = new Clipboard("#copy_text");
      clipboard.on("success", (e) => {
        message.success("复制成功");
        this.shareModalVisible = false;
        clipboard.destroy(); // 释放内存
      });
      clipboard.on("error", (e) => {
        message.error("复制失败"); // 不支持复制
        this.shareModalVisible = false;
        clipboard.destroy(); // 释放内存
      });
    },
  },
  beforeDestroy() {
    sessionStorage.removeItem("tableData");
  },
};
</script>

<style lang="less" scoped>
.invitecode-info {
  background: #ecf8fe;
  padding: 10px 20px;
  margin-bottom: 20px;
  .title {
    font-size: 14px;
    line-height: 20px;
    color: #000;
    margin-bottom: 4px;
  }
  .label,
  .date {
    color: #9fa5af;
  }
}
.table-operator {
  margin-right: 20px;
}
.share {
  width: 95%;
  text-align: center;
  height: 30px;
  line-height: 30px;
  background-color: #efefef;
}
</style>
